<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <nav
        class="
          flex
          mb-8
          justify-between
          sm:px-6
          lg:px-8
          flex-wrap
          gap-y-4
          lg:h-10
          md:h-10
        "
        aria-label="Breadcrumb"
      >
        <ol class="flex items-center space-x-4">
          <li>
            <div>
              <a href="#" class="text-gray-400 hover:text-gray-500">
                <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span class="sr-only">Home</span>
              </a>
            </div>
          </li>
          <li v-for="page in pages" :key="page.name">
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                :href="page.href"
                class="
                  ml-4
                  text-sm
                  font-medium
                  text-gray-500
                  hover:text-gray-700
                "
                :aria-current="page.current ? 'page' : undefined"
                >{{ page.name }}</a
              >
            </div>
          </li>
        </ol>
        <div>
          <div class="relative rounded-md shadow-sm">
            <div
              class="
                absolute
                inset-y-0
                left-0
                pl-3
                flex
                items-center
                pointer-events-none
              "
            >
              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="email"
              @input="debouncedQuery"
              v-model="search"
              class="
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                pl-10
                sm:text-sm
                border-gray-300
                rounded-md
              "
              placeholder="Search for clients"
            />
          </div>
        </div>
      </nav>
      <div
        v-if="client.count > 0"
        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
      >
        <div
          class="
            shadow
            overflow-hidden
            border-b border-gray-200
            sm:rounded-tl-lg sm:rounded-tr-lg
          "
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Client Name
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Case
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                v-for="(client, i) in client.items"
                :key="client.email"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full"
                        :src="`https://source.boringavatars.com/beam/120/${client.email}?colors=0C4A6E,0284C7,D1FAE5,0284C7,e76f51`"
                        alt=""
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ client.info?.first_name }}
                        {{ client.info?.last_name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ client.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-500">
                    {{ client.info?.phone }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap grid gap-2">
                  <template :key="cases.id" v-for="cases in client.cases">
                    <router-link
                      :to="{ name: 'case', params: { id: cases.id } }"
                      :class="
                        cases.info.app_type.uid === 'sale'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      "
                      class="
                        px-2
                        flex
                        text-xs
                        leading-5
                        max-w-max
                        font-semibold
                        rounded-full
                      "
                    >
                      {{ cases.info.app_type.name }} of
                      {{ cases.info.address.street_address }}
                    </router-link>
                  </template>
                </td>
                <td
                  class="
                    px-6
                    py-4
                    whitespace-nowrap
                    text-right text-sm
                    font-medium
                  "
                >
                  <router-link
                    :to="{ name: 'client', params: { id: client.id } }"
                    class="text-blue-600 hover:text-blue-900"
                    >View-></router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          @prev-page="handlePageChanged"
          @next-page="handlePageChanged"
          @page-changed="handlePageChanged"
          :total="client.count"
          :current_page="current_page"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon, HomeIcon, SearchIcon } from "@heroicons/vue/solid";
import { debounce } from "debounce";
import Pagination from "@/components/Pagination";
import { useStore } from "vuex";
import { computed, ref } from "vue";

const pages = [{ name: "Clients", href: "#", current: true }];

export default {
  components: {
    ChevronRightIcon,
    HomeIcon,
    SearchIcon,
    Pagination,
  },
  setup() {
    const store = useStore();
    const current_page = ref(1);
    const search = ref("");

    store.dispatch("user/getClients");

    const handlePageChanged = async (start) => {
      await store.dispatch("user/getClients", { start });
      current_page.value = start;
    };

    return {
      pages,
      handlePageChanged,
      current_page,
      search,
      debouncedQuery: debounce(async function () {
        store.dispatch("user/getClients", {
          page: 1,
          search: search.value,
        });
        current_page.value = 1;
      }, 500),
      client: computed(() => store.state.user.clients),
    };
  },
};
</script>